import { useEffect } from 'react'
import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { LoadingView } from '../views/utils/loadingview'

const Login = () => {
  const { status } = useSession()
  const query = useRouter().query

  useEffect(() => {
    if (query.callbackUrl) {
      signIn('smartcentral', { callbackUrl: query.callbackUrl as string })
    }
  }, [query])

  return (
    <LoadingView
      title={status === 'loading' ? 'Loading page' : 'Authorising'}
    />
  )
}

export default Login
