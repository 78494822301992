import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

interface IProps {
  title?: React.ReactNode | string
}

export const LoadingView = (props: IProps) => {
  return (
    <>
      <Box display='flex' justifyContent='center' paddingTop='40vh'>
        <CircularProgress />
      </Box>

      <Typography variant='h5' align='center' component='div' fontWeight='bold'>
        {props.title}
      </Typography>
    </>
  )
}
